import { getUniqueId } from '../utils/use-unique-id.js';

const NOTIFICATION_TIMEOUT = 5000;
let callback;
let notifications = [];
const subscribe = cb => {
  callback = cb;
};
const unsubscribe = () => {
  for (const notification of notifications) {
    if (notification.interval) {
      clearTimeout(notification.interval);
    }
  }
  notifications = [];
};
const addNotification = (content, options) => {
  var _options$hidden, _notificationOptions$;
  const notificationOptions = {
    autoClose: true,
    ...options
  };
  if (!callback) {
    console.error('Style Guide: <Notifications> component is not mounted.');
    return;
  }
  const id = getUniqueId('notification-');
  const interval = notificationOptions.autoClose ? setTimeout(() => removeNotification(id), NOTIFICATION_TIMEOUT) : undefined;
  notifications.push({
    id,
    interval,
    content,
    hidden: (_options$hidden = options === null || options === void 0 ? void 0 : options.hidden) !== null && _options$hidden !== void 0 ? _options$hidden : false,
    status: (_notificationOptions$ = notificationOptions.status) !== null && _notificationOptions$ !== void 0 ? _notificationOptions$ : 'default'
  });
  callback(notifications);
};
const removeNotification = id => {
  notifications = notifications.filter(notification => {
    if (notification.id !== id) {
      return true;
    } else {
      notification.interval && clearTimeout(notification.interval);
      return false;
    }
  });
  callback(notifications);
};

export { NOTIFICATION_TIMEOUT, addNotification, removeNotification, subscribe, unsubscribe };
