import { useState, useEffect } from 'react';
import tokens from '@te-digi/styleguide-tokens';

function useBreakpoint(breakpoint) {
  const hasMatchMedia = typeof window !== 'undefined' && 'matchMedia' in window;
  const query = {
    sm: "(min-width: ".concat(tokens.breakpoint.sm, ")"),
    md: "(min-width: ".concat(tokens.breakpoint.md, ")"),
    lg: "(min-width: ".concat(tokens.breakpoint.lg, ")"),
    xl: "(min-width: ".concat(tokens.breakpoint.xl, ")")
  }[breakpoint];
  const [state, setState] = useState(hasMatchMedia ? () => window.matchMedia(query).matches : false);
  useEffect(() => {
    if (!hasMatchMedia) {
      return;
    }
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };
    mql.addListener(onChange);
    setState(mql.matches);
    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [hasMatchMedia, query]);
  return state;
}

export { useBreakpoint };
