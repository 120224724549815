import React, { useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import { Context } from './Context'

export const StyledOverlay = styled.div<{
  $blur: boolean
  $grayscale: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2000;

  ${props => css`
    backdrop-filter: ${props.$blur ? 'blur(1px)' : ''}
      ${props.$grayscale ? 'grayscale(100%)' : ''};
  `}
`

export const FilterOverlay = () => {
  const { filters } = useContext(Context)
  const overlay = document.createElement('div')

  useEffect(() => {
    document.body.appendChild(overlay)

    return () => {
      document.body.removeChild(overlay)
    }
  })

  return overlay && (filters.includes('blur') || filters.includes('grayscale'))
    ? ReactDOM.createPortal(
        <StyledOverlay
          $blur={filters.includes('blur')}
          $grayscale={filters.includes('grayscale')}
        />,
        overlay
      )
    : null
}
