import { ComponentProps, createContext } from 'react'

import { Provider as StyleGuideProvider } from '@te-digi/styleguide'

export type Locale = ComponentProps<typeof StyleGuideProvider>['locale']
export type Filters = any[]

const Context = createContext<{
  changeLocale: (locale: Locale) => void
  filters: Filters
  locale: Locale
  setFilters: (filters: Filters) => void
  supportsFilters: boolean
}>({
  changeLocale: () => {
    // This is intentional
  },
  filters: [],
  locale: 'fi',
  setFilters: () => {
    // This is intentional
  },
  supportsFilters: false
})

Context.displayName = 'Context'

export { Context }
