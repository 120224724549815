import { useEffect } from 'react';

const useFocusOut = (enabled, ignoredRefs, callback) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const ignored = Array.isArray(ignoredRefs) ? ignoredRefs : [ignoredRefs];
    const onFocusOut = event => {
      if (event.relatedTarget && !ignored.some(ref => {
        if (!ref) {
          return false;
        }
        if ('current' in ref) {
          var _ref$current$contains, _ref$current;
          // Ref is RefObject
          return (_ref$current$contains = (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.contains(event.relatedTarget)) !== null && _ref$current$contains !== void 0 ? _ref$current$contains : false;
        } else {
          // Ref is HTMLElement
          return ref.contains(event.relatedTarget);
        }
      })) {
        callback();
      }
    };
    document.addEventListener('focusout', onFocusOut);
    return () => {
      document.removeEventListener('focusout', onFocusOut);
    };
  }, [enabled, callback, ignoredRefs]);
};

export { useFocusOut };
