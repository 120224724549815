import React, { ReactNode, useState } from 'react'

import {
  Box,
  MenuIcon,
  Notifications,
  SkipLink,
  Text,
  XIcon
} from '@te-digi/styleguide'
import tyomarkkinatoriSvg from '@te-digi/styleguide/assets/tyomarkkinatori.svg'
import packageJson from '@te-digi/styleguide/package.json'

import { Navigation } from './Navigation'

import {
  StyledButton,
  StyledContent,
  StyledContentSpacer,
  StyledLink,
  StyledLogo,
  StyledName,
  StyledPage,
  StyledSidebar,
  StyledSideBarHeader
} from './Layout.style'

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <>
      <StyledPage>
        <SkipLink />
        <StyledSidebar>
          <StyledSideBarHeader>
            <StyledLink
              aria-label="Etusivu"
              to="/"
            >
              <StyledLogo
                alt="Työmarkkinatori"
                src={tyomarkkinatoriSvg}
              />
              <StyledName>Style Guide</StyledName>
            </StyledLink>
            <StyledButton
              color="white"
              iconRight={isMenuOpen ? <XIcon /> : <MenuIcon />}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              variant="plain"
            >
              Valikko
            </StyledButton>
          </StyledSideBarHeader>
          <Navigation
            isMobileMenuOpen={isMenuOpen}
            onNavItemClick={() => setIsMenuOpen(false)}
          />
        </StyledSidebar>
        <StyledContentSpacer>
          <StyledContent>{children}</StyledContent>
          <Box
            border="none"
            color="light"
            noMargin
            padding={{ xs: 'xl', lg: 'layout-lg' }}
            radius="none"
          >
            <Text
              as="p"
              color="dark"
              size="sm"
            >
              Työmarkkinatori Style Guide v{packageJson.version}
            </Text>
          </Box>
        </StyledContentSpacer>
      </StyledPage>
      <Notifications />
    </>
  )
}

export { Layout }
