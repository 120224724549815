import tokens from '@te-digi/styleguide-tokens';

const media = {
  sm: "@media (min-width: ".concat(tokens.breakpoint.sm, ")"),
  md: "@media (min-width: ".concat(tokens.breakpoint.md, ")"),
  lg: "@media (min-width: ".concat(tokens.breakpoint.lg, ")"),
  xl: "@media (min-width: ".concat(tokens.breakpoint.xl, ")"),
  downMd: "@media (max-width: ".concat(parseInt(tokens.breakpoint.lg) - 0.02, "px)"),
  downSm: "@media (max-width: ".concat(parseInt(tokens.breakpoint.md) - 0.02, "px)"),
  downXs: "@media (max-width: ".concat(parseInt(tokens.breakpoint.sm) - 0.02, "px)"),
  width: width => "@media (min-width: ".concat(width, "px)")
};

export { media };
