import { useEffect } from 'react';

const useDeprecated = (enabled, feature, replacement) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && enabled) {
      console.warn("[Style Guide]: ".concat(feature, " is deprecated. It still works, but might not in the next major version.").concat(replacement ? " Use ".concat(replacement, ".") : '', " This warning is only shown on the development build."));
    }
  }, [enabled, feature, replacement]);
};

export { useDeprecated };
