import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { StyledCol } from './Col.js';
import { getResponsiveStyle } from '../utils/styles/get-responsive-style.js';

const getGapInRems = gap => gap ? "".concat(parseFloat(tokens.space[gap]) / 2, "rem") : '1rem';
const getRowGapInRems = gap => gap ? "".concat(parseFloat(tokens.space[gap]), "rem") : '0';
const StyledRow = styled.div.withConfig({
  componentId: "sg1670__sc-1i273rn-0"
})(["display:flex;flex-wrap:wrap;row-gap:", ";margin-right:", ";margin-left:", ";", " ", " > ", "{padding-left:", ";padding-right:", ";}"], props => getRowGapInRems(props.$rowGap), props => "-".concat(getGapInRems(props.$gap)), props => "-".concat(getGapInRems(props.$gap)), props => getResponsiveStyle('align-items', props.$alignItems), props => getResponsiveStyle('justify-content', props.$justifyContent), StyledCol, props => getGapInRems(props.$gap), props => getGapInRems(props.$gap));

export { StyledRow };
