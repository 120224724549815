import React, { useContext } from 'react'

import {
  Checkbox,
  CheckboxGroup,
  CircleButton,
  Dropdown,
  DropdownSection,
  FormGroup,
  MoreFillIcon,
  Radio,
  RadioGroup
} from '@te-digi/styleguide'

import { Context } from './Context'

const Filters = () => {
  const { filters, locale, changeLocale, setFilters, supportsFilters } =
    useContext(Context)

  return (
    <Dropdown
      placement="top-start"
      trigger={<CircleButton icon={<MoreFillIcon />}>Näkymä</CircleButton>}
    >
      <DropdownSection>
        {supportsFilters && (
          <FormGroup>
            <CheckboxGroup
              label="Saavutettavuus"
              name="accessibility"
              onChange={value => setFilters(value)}
              value={filters}
            >
              <Checkbox value="grayscale">Värisokeus</Checkbox>
              <Checkbox value="blur">Heikentynyt näkö</Checkbox>
            </CheckboxGroup>
          </FormGroup>
        )}
        <RadioGroup
          label="Komponenttien kieli"
          onChange={value => changeLocale(value as typeof locale)}
          value={locale}
        >
          {[
            { label: 'suomi', value: 'fi' },
            { label: 'ruotsi', value: 'sv' },
            { label: 'englanti', value: 'en' }
          ].map(language => (
            <Radio
              key={language.value}
              value={language.value}
            >
              {language.label}
            </Radio>
          ))}
        </RadioGroup>
      </DropdownSection>
    </Dropdown>
  )
}

export { Filters }
