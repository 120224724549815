import React, { ElementType, ReactNode, useState } from 'react'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  Col,
  Row,
  Spacer
} from '@te-digi/styleguide'

import { FLAT_PAGES, Page, PAGES } from '../pages'

import { Filters } from './Filters'
import {
  StyledDivider,
  StyledLink,
  StyledMenu,
  StyledNavigation,
  StyledNavigationContainer,
  StyledSearchInput
} from './Navigation.style'

interface NavItemsProps {
  items?: Page[]
  level?: number
  onNavItemClick(): void
}

interface ItemProps {
  children: ReactNode
  external?: boolean
  icon?: ElementType
  level?: number
  onClick?(): void
  status?: 'collapsed' | 'expanded'
  to?: string
}

const Item = ({
  children,
  external,
  icon: Icon,
  level,
  onClick,
  status,
  to
}: ItemProps) => {
  const label = Icon ? (
    <Row
      alignItems="center"
      gap="md"
    >
      <Col xs="auto">
        <Icon
          block
          size="lg"
        />
      </Col>
      <Col>{children}</Col>
      {status && (
        <Col xs="auto">
          {status === 'collapsed' && (
            <ChevronRightIcon
              block
              size="lg"
            />
          )}
          {status === 'expanded' && (
            <ChevronDownIcon
              block
              size="lg"
            />
          )}
        </Col>
      )}
    </Row>
  ) : (
    children
  )

  return to ? (
    external ? (
      <StyledLink
        $level={level}
        external={external}
        href={to}
      >
        {label}
      </StyledLink>
    ) : (
      <StyledLink
        $level={level}
        forwardedAs={Link}
        getProps={(props: any) =>
          props.isCurrent ? { 'aria-current': 'page' } : {}
        }
        onClick={onClick}
        to={to}
      >
        {label}
      </StyledLink>
    )
  ) : (
    <>{label}</>
  )
}

const NavItems = ({ items, level = 1, onNavItemClick }: NavItemsProps) => {
  const location = useLocation()

  return (
    <StyledMenu>
      {items?.map(item => {
        const childItemsVisible =
          location.pathname.startsWith(item.to) ||
          location.pathname.startsWith(`/latest${item.to}`)

        const status = item.children
          ? childItemsVisible
            ? 'expanded'
            : 'collapsed'
          : undefined

        return (
          <li key={item.to}>
            {item.title === '-' ? (
              <StyledDivider />
            ) : (
              <Item
                external={item.external}
                icon={item.icon}
                level={level}
                onClick={onNavItemClick}
                to={item.to}
                status={status}
              >
                {item.title}
              </Item>
            )}
            {item.children && childItemsVisible && (
              <NavItems
                items={item.children}
                level={2}
                onNavItemClick={onNavItemClick}
              />
            )}
          </li>
        )
      })}
    </StyledMenu>
  )
}

interface NavigationProps {
  isMobileMenuOpen: boolean
  onNavItemClick(): void
}

const search = (searchTerm: string) => {
  const resultsStartingWith = FLAT_PAGES.filter(item =>
    item.title.toLowerCase().startsWith(searchTerm)
  )

  const resultsIncludes = FLAT_PAGES.filter(
    item =>
      !resultsStartingWith.includes(item) &&
      item.title.toLowerCase().includes(searchTerm)
  )

  return [...resultsStartingWith, ...resultsIncludes]
}

const Navigation = ({ isMobileMenuOpen, onNavItemClick }: NavigationProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const searchTermTrimmed = searchTerm.trim().toLowerCase()
  const results = searchTermTrimmed ? search(searchTermTrimmed) : []

  return (
    <StyledNavigationContainer isMobileMenuOpen={isMobileMenuOpen}>
      <Spacer
        marginBottom="md"
        marginTop="md"
        marginLeft={{ xs: 'md', md: 'xl' }}
        marginRight={{ xs: 'md', md: 'xl' }}
      >
        <Row gap="md">
          <Col>
            <form
              onSubmit={event => event.preventDefault()}
              role="search"
            >
              <StyledSearchInput
                ariaLabel="Haku"
                clearable
                onChange={event => setSearchTerm(event.target.value)}
                placeholder="Hae…"
                value={searchTerm}
              />
            </form>
          </Col>
          <Col xs="auto">
            <Filters />
          </Col>
        </Row>
      </Spacer>
      <StyledNavigation aria-label="Päävalikko">
        {searchTerm ? (
          <StyledMenu>
            {results.map(result => (
              <li key={`${result.title} ${result.to}`}>
                <Item
                  external={result.external}
                  to={result.to}
                >
                  {result.title}
                </Item>
              </li>
            ))}
          </StyledMenu>
        ) : (
          <NavItems
            items={PAGES}
            onNavItemClick={onNavItemClick}
          />
        )}
      </StyledNavigation>
    </StyledNavigationContainer>
  )
}

export { Navigation }
