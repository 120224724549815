const isSSR = () => typeof window === 'undefined';
const isSafari = () => {
  if (isSSR()) {
    return false;
  }
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome');
};

export { isSSR, isSafari };
