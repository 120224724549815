import styled, { css } from 'styled-components'
import tokens from '@te-digi/styleguide-tokens'

import { Input, Link } from '@te-digi/styleguide'

export const StyledMenu = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  & & {
    margin-bottom: ${tokens.space.sm};
    margin-left: 1rem;
  }
`

export const StyledLink = styled(Link)<{ $level?: number }>`
  color: ${tokens.color['link-on-dark']};
  display: block;
  font-weight: ${tokens['font-weight']['semi-bold']};
  margin: ${tokens.space.xxxs} -${tokens.space.xl};
  padding: ${tokens.space.xs} ${tokens.space.xl};
  text-decoration: none;

  ${props =>
    props.$level === 2 &&
    css`
      border-bottom-left-radius: ${tokens.radius.pill};
      border-top-left-radius: ${tokens.radius.pill};
      font-size: ${tokens['font-size'].sm};
      line-height: ${tokens['line-height'].sm};
      margin-left: 0;
      padding-left: ${tokens.space.lg};
      padding-right: ${tokens.space.lg};
    `}

  &:hover,
  &[aria-current='page'] {
    background-color: ${tokens.color['primary']};
    color: ${tokens.color.white};
  }

  &:focus-visible {
    border-radius: 0;
    background-color: ${tokens.color['primary']};
    color: ${tokens.color.white};
    outline: 0;
  }

  &:active {
    background-color: ${tokens.color['primary-dark']};
  }
`

export const StyledDivider = styled.div`
  border-top: 1px solid ${tokens.color['primary-light']};
  margin: ${tokens.space.xs} 0;
  margin-left: ${tokens.space.lg};
`

export const StyledNavigationContainer = styled.div<{
  isMobileMenuOpen: boolean
}>`
  display: ${props => (props.isMobileMenuOpen ? 'flex' : 'none')};
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0;

  @media (min-width: ${tokens.breakpoint.md}) {
    display: flex;
  }
`

export const StyledNavigation = styled.nav`
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: 1rem;
  padding-top: 0;
  width: 100%;

  @media (min-width: ${tokens.breakpoint.md}) {
    padding: 2rem;
    padding-top: 0;
  }
`

export const StyledSearchInput = styled(Input)`
  border: 0;
  border-radius: ${tokens.radius.pill};
  padding-left: ${tokens.space.md};

  *:not(:focus-within) > &:placeholder-shown {
    background-color: ${tokens.color['primary-dark']};
    color: ${tokens.color.white};
  }

  &:not(:focus)::placeholder {
    color: ${tokens.color['link-on-dark']};
  }
`
