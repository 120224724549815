import { useState } from 'react';

let globalCount = 0;
const hash = Math.random().toString(36).substr(2, 5);
const getUniqueId = function () {
  let prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "".concat(prefix ? "".concat(prefix, "-") : '').concat(hash, "-").concat(++globalCount);
};
const useUniqueId = function () {
  let prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  const [uniqueId] = useState(getUniqueId(prefix));
  return uniqueId;
};

export { getUniqueId, useUniqueId };
