import { useContext, useMemo } from 'react';
import { Context } from '../Context/Context.js';

/**
 * Usage:
 *
 * const { t } = useTranslation()
 * return <h1>{t('some-translation-id')}</h1>
 */
function useTranslation() {
  const {
    locale,
    translations
  } = useContext(Context);
  return useMemo(() => ({
    getTranslation: id => translations[locale][id] || "(Missing translation: ".concat(id, ")")
  }), [locale, translations]);
}

export { useTranslation };
