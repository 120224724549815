import tokens from '@te-digi/styleguide-tokens';
import { media } from './media.js';

const actualValue = {
  'align-items': {
    end: 'flex-end',
    start: 'flex-start'
  },
  'align-self': {
    end: 'flex-end',
    start: 'flex-start'
  },
  'font-size': tokens['font-size'],
  'justify-content': {
    around: 'space-around',
    between: 'space-between',
    end: 'flex-end',
    start: 'flex-start'
  },
  'order': {
    first: '-1',
    last: '13'
  }
};
const getStyle = (breakpoint, property, value) => {
  var _actualValue$property;
  if (value === undefined) {
    return;
  }
  const style = "".concat(property, ": ").concat(((_actualValue$property = actualValue[property]) === null || _actualValue$property === void 0 ? void 0 : _actualValue$property[value]) || value, ";");
  return breakpoint === 'xs' ? style : "".concat(media[breakpoint], " { ").concat(style, " }");
};
const getResponsiveStyle = (property, value) => {
  if (value === undefined) {
    return;
  }
  if (typeof value === 'object') {
    return Object.keys(value).map(breakpoint => getStyle(breakpoint, property, value[breakpoint])).filter(Boolean).join('\n');
  }
  return getStyle('xs', property, value);
};

export { getResponsiveStyle };
