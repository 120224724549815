import { useEffect } from 'react';

const useOutsideClick = (enabled, ignoredRefs, callback) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }
    const refs = Array.isArray(ignoredRefs) ? ignoredRefs : [ignoredRefs];
    const onOutsideClick = event => {
      if (refs.every(ref => {
        if (!ref) {
          return true;
        }

        // Ref is RefObject
        if ('current' in ref) {
          if (ref.current) {
            return !ref.current.contains(event.target);
          }
          return true;
        }

        // Ref is HTMLElement
        return !ref.contains(event.target);
      })) {
        callback();
      }
    };
    const onKeyDown = event => {
      if (event.key === 'Escape') {
        callback();
      }
    };
    document.addEventListener('click', onOutsideClick);
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('click', onOutsideClick);
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [enabled, callback, ignoredRefs]);
};

export { useOutsideClick };
