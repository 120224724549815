import React, { ReactNode, useState } from 'react'
import { Provider as StyleGuideProvider } from '@te-digi/styleguide'

import { Context, Filters, Locale } from './Context'
import { FilterOverlay } from './FilterOverlay'

interface ProviderProps {
  children: ReactNode
}

const supportsFilters =
  typeof window !== 'undefined' &&
  'CSS' in window &&
  CSS.supports('backdrop-filter', 'blur(1px)')

const Provider = ({ children }: ProviderProps) => {
  const [locale, setLocale] = useState<Locale>('fi')
  const [filters, setFilters] = useState<Filters>([])

  return (
    <Context.Provider
      value={{
        changeLocale: temp_locale => setLocale(temp_locale),
        filters,
        setFilters,
        supportsFilters,
        locale
      }}
    >
      <StyleGuideProvider locale={locale}>{children}</StyleGuideProvider>
      {supportsFilters && <FilterOverlay />}
    </Context.Provider>
  )
}

export { Provider }
