import React from 'react'

import { Provider } from './src/components/Provider'
import { Layout } from './src/components/Layout'

export const wrapPageElement = ({ element, props }) =>
  /\/mallit\/\w+\/?/.test(props.location.pathname) ? (
    element
  ) : (
    <Layout {...props}>{element}</Layout>
  )

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>
