import styled from 'styled-components'
import { Link } from 'gatsby'
import tokens from '@te-digi/styleguide-tokens'
import { Button, Main } from '@te-digi/styleguide'

export const StyledPage = styled.div`
  background-attachment: fixed;
  background-image: linear-gradient(
    to bottom,
    ${tokens.color.dark} 33%,
    #3f197d 100%
  );
  margin: 0 auto;
`

export const StyledSidebar = styled.header`
  background-color: ${tokens.color.dark};
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${tokens['z-index'].fixed};
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${tokens.breakpoint.md}) {
    align-items: flex-start;
    background-color: transparent;
    bottom: 0;
    flex-direction: column;
    flex-wrap: nowrap;
    left: auto;
    right: auto;
    width: 16rem;
  }

  @media (min-width: ${tokens.breakpoint.lg}) {
    width: 18rem;
  }
`

export const StyledSideBarHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem;

  @media (min-width: ${tokens.breakpoint.md}) {
    padding: 2rem;
    padding-bottom: ${tokens.space.md};
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  display: block;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 100%;
  text-decoration: none;
  position: relative;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`

export const StyledLogo = styled.img`
  display: block;
  height: 2.75rem;

  @media (min-width: ${tokens.breakpoint.md}) {
    height: 3.75rem;
  }

  @media (min-width: ${tokens.breakpoint.lg}) {
    height: 4.25rem;
  }
`

export const StyledContentSpacer = styled.div`
  margin-top: 4.75rem;
  overflow: hidden;
  position: relative;
  z-index: 0;

  @media (min-width: ${tokens.breakpoint.md}) {
    box-shadow: 0 0 4rem rgba(0, 0, 0, 0.4);
    margin-left: 16rem;
    margin-top: 0;
  }

  @media (min-width: ${tokens.breakpoint.lg}) {
    margin-left: 18rem;
  }
`

export const StyledContent = styled(Main)`
  background-color: ${tokens.color.white};
  min-height: 100vh;
`

export const StyledName = styled.div`
  bottom: 0;
  color: ${tokens.color.white};
  font-size: 0.625rem;
  line-height: 1;
  position: absolute;
  right: 0;
  text-decoration: none;

  @media (min-width: ${tokens.breakpoint.md}) {
    font-size: ${tokens['font-size']['md']};
  }
`

export const StyledButton = styled(Button)`
  margin-left: auto;

  @media (min-width: ${tokens.breakpoint.md}) {
    display: none;
  }
`
